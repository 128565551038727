"use client";
import React from "react";
import PictureCard from "./pictureCard";
import MysnoryLayout from "@/components/shared/pictureCard/mysnoryLayout";
import { useDisplayModeContext } from "../../../app/context/displayModeStore";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  videoUrl: string;
  thumbnailUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface CardWrapperProps {
  data: Post[];
  contentType: string;
  modalTranslations: any;
  signInModalTranslations: any;
}

const CardWrapper = React.memo(
  function CardWrapper({
    data,
    contentType,
    modalTranslations,
    signInModalTranslations,
  }: CardWrapperProps) {
    const { state } = useDisplayModeContext();
    return (
      <MysnoryLayout state={state}>
        {data.map((card: Post, i: number) => (
          <PictureCard
            key={card._id}
            firstImg={i}
            data={card}
            state={state}
            contentType={contentType}
            modalTranslations={modalTranslations}
            signInModalTranslations={signInModalTranslations}
          />
        ))}
      </MysnoryLayout>
    );
  },
  (prevProps, nextProps) => {
    // Fast path: quickly check content type and length first
    if (prevProps.contentType !== nextProps.contentType) return false;
    if (prevProps.data.length !== nextProps.data.length) return false;

    // Fast direct comparison without creating intermediate arrays
    for (let i = 0; i < prevProps.data.length; i++) {
      if (prevProps.data[i]._id !== nextProps.data[i]._id) return false;
    }

    return true;
  },
);

export default CardWrapper;
